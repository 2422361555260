import {
  SimulationLinkDatum,
  SimulationNodeDatum,
  forceLink,
  forceSimulation,
  forceX,
  forceY,
  forceCollide,
} from 'd3-force';
import {
  action,
  tween,
  parallel,
  chain,
  delay,
  ColdSubscription,
} from 'popmotion';
import { BubblesData } from '../types';
import {
  getRadius,
  getCollideRadiusFactor,
  defaultEaseCurve,
  initializeNodes, getRotationSinAndCosFromTransform,
} from './utils';
import { drawCircle } from './circle';
import { renderer } from './renderer';
import { getCanvasCoordinates } from './matrices';
import './styles.css';

const COLORS = {
  GREAT: '#3f63a7',
  ACCEPTABLE: '#3f93a7',
  SUSPICIOUS: '#f10000',
};

const ASPECT_RATIO = window.devicePixelRatio;

const COLORS_WITH_ALPHA = {
  [COLORS.GREAT]: (alpha: number = 0.4) => `rgba(63, 99, 167, ${alpha})`,
  [COLORS.ACCEPTABLE]: (alpha: number = 0.4) => `rgba(63, 147, 167, ${alpha})`,
  [COLORS.SUSPICIOUS]: (alpha: number = 0.4) => `rgba(241, 0, 0, ${alpha})`,
};

export interface PointDatum extends SimulationNodeDatum {
  r: number;
  visualRadius: number;
  color: string;
  type: string;
}

export interface BubbleChartOptions {
  onMouseOver?(data: PointDatum): void;
  onMouseOut?(): void;
  pointDistance?: number;
  initialPointRadius?: number;
  canvasPadding?: number;
  collideRadiusFactor?: number;
  positionStrength?: number;
  linkStrength?: number;
  prerenderIterations?: number;
  enterDuration?: number;
  alphaSpreadingDelay?: number;
  hoverTransitionDuration?: number;
}

export interface BubbleChartControls {
  setData(data: BubblesData): Promise<void>;
  destroy(): void;
}

export default function init(
  canvas: HTMLCanvasElement,
  {
    canvasPadding = 50,
    pointDistance = 4,
    initialPointRadius = 6,
    prerenderIterations = 50,
    positionStrength = 0.001,
    linkStrength = 1,
    enterDuration = 1000,
    alphaSpreadingDelay = 500,
    hoverTransitionDuration = 200,
    onMouseOver,
    onMouseOut,
  }: BubbleChartOptions
): BubbleChartControls {
  const canvasWidth = canvas.clientWidth;
  const canvasHeight = canvas.clientHeight;

  const width = canvasWidth * ASPECT_RATIO;
  const height = canvasHeight * ASPECT_RATIO;
  const { top: canvasTop, left: canvasLeft } = canvas.getBoundingClientRect();

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Cannot create 2d context');
  }

  ctx.translate(width / 2, height / 2);
  const center = [0, 0];

  let pointsData: BubblesData | undefined;
  // Frame state variables
  let points: PointDatum[] = [];
  let links: SimulationLinkDatum<PointDatum>[] = [];
  let radius: number = 0;
  let scale: number = 1;
  let angle: number = 0;
  let visible: number = 0;
  let filled: number = 0;
  let selected: string | null = null;
  let alphaByColor: Record<string, number> = {
    [COLORS.GREAT]: 1,
    [COLORS.ACCEPTABLE]: 1,
    [COLORS.SUSPICIOUS]: 1,
  };

  const renderDots = () => {
    ctx.save();
    ctx.resetTransform();
    ctx.clearRect(0, 0, width, height);
    ctx.restore();
    // ctx.clearRect(-width / 2, -height / 2, width, height);
    points.forEach((d) => {
      let color = '';

      if (visible < points.length) {
        const index = Number(d.index ?? 0);
        let alpha: number;

        if (index <= filled) {
          alpha = 1;
        } else if (index > visible) {
          alpha = 0;
        } else {
          alpha = 1 - ((index - filled) / (visible - filled || 1)) * 0.8;
        }

        color = COLORS_WITH_ALPHA[d.color](alpha);
      } else {
        if (selected) {
          color = COLORS_WITH_ALPHA[d.color](alphaByColor[d.color]);
        } else {
          color = d.color;
        }
      }

      drawCircle(ctx, d.x, d.y, d.visualRadius, color);
    });
  };

  const render = renderer(renderDots);

  const enterPoints = () => {
    const simulation = forceSimulation<PointDatum>(points)
      .force(
        'link',
        forceLink(links).distance(pointDistance).strength(linkStrength)
      )
      .force('x', forceX(center[0]).strength(-positionStrength))
      .force('y', forceY(center[1]).strength(-positionStrength))
      .stop();

    radius = getRadius(points, initialPointRadius);

    scale = (height - canvasPadding * 2) / (radius * 1.1 * 2);
    ctx.scale(scale, scale);

    const areaByPoint = (Math.PI * radius ** 2) / points.length;

    const getSubAreaCenter = (r: number) => r + (radius - 2 * r) / 2;

    const getSubAreaIndexes = (count: number, left: boolean = false) => {
      const r = Math.sqrt((count * areaByPoint) / Math.PI);
      const center = { x: (left ? -1 : 1) * getSubAreaCenter(r), y: 0 };

      const indexes = points
        .map((d) => ({
          ...d,
          distance: (center.x - (d.x ?? 0)) ** 2 + (center.y - (d.y ?? 0)) ** 2,
        }))
        .sort((a, b) => a.distance - b.distance)
        .slice(0, count);

      return indexes.map((d) => d.index);
    };

    const suspiciousItems = getSubAreaIndexes(pointsData?.suspicious ?? 0);
    for (const point of points) {
      if (
        suspiciousItems.includes(point.index) &&
        point.type !== 'acceptable'
      ) {
        point.color = COLORS.SUSPICIOUS;
        point.type = 'suspicious';
      }
    }

    const acceptableItems = getSubAreaIndexes(
      pointsData?.acceptable ?? 0,
      true
    );
    for (const point of points) {
      if (
        acceptableItems.includes(point.index) &&
        point.type !== 'suspicious'
      ) {
        point.color = COLORS.ACCEPTABLE;
        point.type = 'acceptable';
      }
    }

    const simulate = action(({ update, complete }) => {
      simulation
        .force(
          'collide',
          forceCollide<PointDatum>()
            .radius((d) => d.r + pointDistance)
            .iterations(1)
        )
        .tick(prerenderIterations)
        // @ts-ignore
        .on('tick', () => update())
        .on('end', complete)
        .restart();
    });

    for (const point of points) {
      point.visualRadius = point.r * getCollideRadiusFactor(points.length);
    }

    const fadeIn = action(({ update, complete }) => {
      parallel(
        tween({
          from: 0,
          to: points.length * 1.4,
          duration: enterDuration,
          ease: defaultEaseCurve,
        }),
        chain(
          delay(alphaSpreadingDelay),
          tween({
            from: 0,
            to: points.length,
            duration: enterDuration,
            ease: defaultEaseCurve,
          })
        )
      ).start({
        update: ([v, f]: [number, number]) => {
          visible = v;
          filled = f ?? 0;
          update();
        },
        complete,
      });
    });

    return Promise.all(
      [simulate, fadeIn].map(
        (act) =>
          new Promise((resolve) => {
            act.start({ update: render, complete: resolve });
          })
      )
    );
  };

  let currentFocusAction: ColdSubscription | null;
  const animateFocus = (
    greatTarget: number,
    acceptableTarget: number,
    suspiciousTarget: number
  ) => {
    if (currentFocusAction) {
      currentFocusAction.stop();
    }
    currentFocusAction = tween({
      from: [
        alphaByColor[COLORS.GREAT],
        alphaByColor[COLORS.ACCEPTABLE],
        alphaByColor[COLORS.SUSPICIOUS],
      ],
      to: [greatTarget, acceptableTarget, suspiciousTarget],
      ease: defaultEaseCurve,
      duration: hoverTransitionDuration,
    }).start({
      update: ([g, a, s]: [number, number, number]) => {
        alphaByColor[COLORS.GREAT] = g;
        alphaByColor[COLORS.ACCEPTABLE] = a;
        alphaByColor[COLORS.SUSPICIOUS] = s;

        render();
      },
      complete: () => {
        currentFocusAction = null;
      },
    });
  };

  const halfWidth = width / 2;
  const halfHeight = height / 2;

  const handleMouseMove = (e: MouseEvent) => {
    const x = ((e.clientX - canvasLeft) * ASPECT_RATIO - halfWidth) / scale;
    const y = ((e.clientY - canvasTop) * ASPECT_RATIO - halfHeight) / scale;

    if (x ** 2 + y ** 2 <= getRadius(points, initialPointRadius) ** 2) {
      const [cos, sin] = getRotationSinAndCosFromTransform(canvas);
      const canvasCoordinates = getCanvasCoordinates(0, 0, 1, cos, sin);
      const item = points.find((point) => {
        const [itemX, itemY] = canvasCoordinates(point.x ?? 0, point.y ?? 0);

        return (
          (x - itemX) ** 2 + (y - itemY) ** 2 <
          (point.visualRadius * scale) ** 2
        );
      });

      if (item && item.color !== selected) {
        selected = item.color;

        animateFocus(
          selected === COLORS.GREAT ? 1 : 0.4,
          selected === COLORS.ACCEPTABLE ? 1 : 0.4,
          selected === COLORS.SUSPICIOUS ? 1 : 0.4
        );

        canvas.classList.add('animation-paused');

        if (onMouseOver) onMouseOver(item);
      }
    } else if (selected) {
      selected = null;

      animateFocus(1, 1, 1);
      canvas.classList.remove('animation-paused');
      if (onMouseOut) onMouseOut();
    }
  };

  canvas.addEventListener('mousemove', handleMouseMove);

  const setData = async (data: BubblesData) => {
    pointsData = data;

    points = Array.from({ length: pointsData.total }, () => ({
      r: initialPointRadius,
      visualRadius: initialPointRadius,
      color: COLORS.GREAT,
      type: 'great',
    }));

    const linksCount = initializeNodes(points);

    links = [
      ...Array.from({ length: linksCount - 1 }, (_, i) => ({
        source: points.length - 1 - i,
        target: points.length - 1 - i - 1,
      })),
      {
        source: points.length - linksCount,
        target: points.length - 1,
      },
    ];

    await enterPoints();
  };

  return {
    setData,
    destroy: () => {
      canvas.removeEventListener('mousemove', handleMouseMove);
    },
  };
}
