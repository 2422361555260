// prettier-ignore
export type TransformMatrix = [
  number, number, number, number,
  number, number, number, number,
  number, number, number, number,
  number, number, number, number
];

export type Point3D = [number, number, number, number];

export type Point2D = [number, number];

export function multiplyPoint(
  matrix: TransformMatrix,
  point: Point3D
): Point3D {
  const [x, y, z, w] = point;

  // prettier-ignore
  const [
    c1r1, c2r1, c3r1, c4r1,
    c1r2, c2r2, c3r2, c4r2,
    c1r3, c2r3, c3r3, c4r3,
    c1r4, c2r4, c3r4, c4r4,
  ] = matrix;

  return [
    x * c1r1 + y * c1r2 + z * c1r3 + w * c1r4,
    x * c2r1 + y * c2r2 + z * c2r3 + w * c2r4,
    x * c3r1 + y * c3r2 + z * c3r3 + w * c3r4,
    x * c4r1 + y * c4r2 + z * c4r3 + w * c4r4,
  ];
}

export function multiplyPoint2D(
  matrix: TransformMatrix,
  point: Point2D
): Point2D {
  const [x, y] = point;
  const result = multiplyPoint(matrix, [x, y, 0, 0]);

  return [result[0], result[1]];
}

export function canvasTransformMatrix(
  x: number,
  y: number,
  scale: number,
  cos: number,
  sin: number,
): TransformMatrix {
  // prettier-ignore
  return [
    scale * cos, sin, 0, 0,
    -sin, scale * cos, 0, 0,
    0, 0, 1, 0,
    x, y, 0, 0,
  ];
}

export function getCanvasCoordinates(
  translateX: number,
  translateY: number,
  scale: number,
  cos: number,
  sin: number,
): (x: number, y: number) => Point2D {
  const matrix = canvasTransformMatrix(translateX, translateY, scale, cos, sin);

  return (x: number, y: number) => multiplyPoint2D(matrix, [x, y] as Point2D);
}
