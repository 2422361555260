import * as dat from 'dat.gui';
import qs from 'qs';

import init from './bubbles';

const defaultOptions = {
  total: 1000,
  acceptable: 142,
  suspicious: 40,
  steppedAnimation: false,
  pointDistance: 4,
  initialPointRadius: 6,
  canvasPadding: 50,
  collideRadiusFactor: 1.2,
  positionStrength: 0.001,
  linkStrength: 1,
  prerenderIterations: 50,
  enterDuration: 1000,
  alphaSpreadingDelay: 500,
  hoverTransitionDuration: 200,
  rotationDuration: 180000,
}

const urlOptions: any = qs.parse((window.location.search ?? '').replace('?', '')) ?? {};

Object.keys(urlOptions).forEach(key => {
  if (key === 'steppedAnimation') {
    urlOptions[key] = Boolean(urlOptions[key]);
  } else  {
    urlOptions[key] = Number(urlOptions[key]);
  }
});

const diff = (options: any) => {
  const diffObj: any = {};

  Object.keys(options).forEach(key => {
    // @ts-ignore
    if (options[key] !== defaultOptions[key]) {
      diffObj[key] = options[key];
    }
  });

  return diffObj;
}

const options = {
  ...defaultOptions,
  ...urlOptions,
}

const CANVAS = {
  WIDTH: 720,
  HEIGHT: 720,
};

const canvas = document.getElementById("canvas") as HTMLCanvasElement;
canvas.style.width = `${CANVAS.WIDTH}px`;
canvas.style.height = `${CANVAS.HEIGHT}px`;

let chart = init(canvas, options);
chart.setData({
  total: options.total,
  great: options.total - (options.acceptable + options.suspicious),
  acceptable: options.acceptable,
  suspicious: options.suspicious,
});
canvas.classList.add('animate');
canvas.style.animationDuration = `${options.rotationDuration}ms`;

const render = () => {
  window.history.pushState(options, 'options', `?${qs.stringify(diff(options))}`);
  canvas.classList.remove('animate');
  setTimeout(() => {
    canvas.classList.add('animate');
  }, 100);


  chart?.destroy();
  chart = init(canvas, options);
  chart.setData({
    total: options.total,
    great: options.total - (options.acceptable + options.suspicious),
    acceptable: options.acceptable,
    suspicious: options.suspicious,
  });
}

const GUI = new dat.GUI({ name: 'Settings' });
GUI.width = 350;

const points = GUI.addFolder('Points settings');
points
  .add(options, 'total', 0, 2500, 1)
  .onFinishChange(render);
points
  .add(options, 'acceptable', 0, 2500, 1)
  .onFinishChange(render);
points
  .add(options, 'suspicious', 0, 2500, 1)
  .onFinishChange(render);
points
  .add(options, 'initialPointRadius', 1, 10, 1)
  .onFinishChange(render);
points
  .add(options, 'pointDistance', 0, 50, 1)
  .onFinishChange(render);
points
  .add(options, 'prerenderIterations', 0, 300, 1)
  .onFinishChange(render);

const forces = GUI.addFolder('Forces');
// const charge = forces.addFolder('Charge');
// charge
//   .add(options, 'chargeForce')
//   .onFinishChange(render);
// charge
//   .add(options, 'chargeTheta', 0, 3, 0.1)
//   .onFinishChange(render);

const collide = forces.addFolder('Collide');
// collide
//   .add(options, 'autoCollideRadiusFactor', true)
//   .onFinishChange(render);
collide
  .add(options, 'collideRadiusFactor', 1, 3, 0.01)
  .onFinishChange(render);

const center = forces.addFolder('Center');
// center
//   .add(options, 'autoPositionStrength', true)
//   .onFinishChange(render);
center
  .add(options, 'positionStrength')
  .onFinishChange(render);

const link = forces.addFolder('Link');
// link
//   .add(options, 'autoLinkStrength', true)
//   .onFinishChange(render);
link
  .add(options, 'linkStrength')
  .onFinishChange(render);

const canvasSettings = GUI.addFolder('Canvas settings')
canvasSettings
  .add(options, 'canvasPadding')
  .onFinishChange(render);

const animation = GUI.addFolder('Animation');
animation
  .add(options, 'steppedAnimation', false)
  .onFinishChange(render);
animation
  .add(options, 'enterDuration')
  .onFinishChange(render);
animation
  .add(options, 'alphaSpreadingDelay')
  .onFinishChange(render);
animation
  .add(options, 'hoverTransitionDuration')
  .onFinishChange(render);
animation
  .add(options, 'rotationDuration')
  .onFinishChange(() => {
    canvas.style.animationDuration = `${options.rotationDuration}ms`;
  });
