export const renderer = (renderCallback: () => void) => {
  let renderRequested: boolean | undefined = false;

  function renderFrame() {
    renderRequested = undefined;

    renderCallback();
  }

  function requestRenderIfNotRequested() {
    if (!renderRequested) {
      renderRequested = true;
      requestAnimationFrame(renderFrame);
    }
  }

  return requestRenderIfNotRequested;
}
