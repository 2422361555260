import { cubicBezier } from '@popmotion/easing';
import { PointDatum } from './index';

export const MAX_N = 2500;

export const linear = (x1: number, y1: number, x2: number, y2: number) => (x: number) =>
  ((y2 - y1) / (x2 - x1)) * x + y2 - ((y2 - y1) / (x2 - x1)) * x2;

export const defaultEaseCurve = cubicBezier(0.25, 0.1, 0.25, 1);

export const getCollideRadiusFactor = (n: number) => {
  const min = 1.2;
  const max = 1.35;

  if (n <= 1500) {
    return max;
  }

  return linear(500, max, MAX_N, min)(n);
}

export const getRadius = (nodes: PointDatum[], pointRadius: number) => {
  return Math.sqrt((nodes[nodes.length - 1].x ?? 0) ** 2 + (nodes[nodes.length - 1].y ?? 0) ** 2) + pointRadius;
}

export function initializeNodes(nodes: PointDatum[]) {
  const initialRadius = 10;
  const initialAngle = Math.PI * (3 - Math.sqrt(5));
  const maxRadius = initialRadius * (Math.sqrt(0.5 + nodes.length));
  const linksCount = Math.round(Math.PI * maxRadius / initialRadius)
  const stepAngle = 2 * Math.PI / linksCount;

  for (let i = 0; i < nodes.length - linksCount; ++i) {
    const node = nodes[i];
    const radius = initialRadius * Math.sqrt(0.5 + i);
    const angle = i * initialAngle;

    node.x = radius * Math.cos(angle);
    node.y = radius * Math.sin(angle);

    node.index = i;
  }

  for (let i = nodes.length - linksCount; i < nodes.length; i++) {

    const node = nodes[i];
    const angle = i * stepAngle;

    node.x = maxRadius * Math.cos(angle);
    node.y = maxRadius * Math.sin(angle);

    node.index = i;
  }

  return linksCount;
}

export const getRotationSinAndCosFromTransform = (el: HTMLElement) => {
  const style = window.getComputedStyle(el, null);
  const transform = style.getPropertyValue("-webkit-transform") ||
    style.getPropertyValue("-moz-transform") ||
    style.getPropertyValue("-ms-transform") ||
    style.getPropertyValue("-o-transform") ||
    style.getPropertyValue("transform");

  const values = transform.split('(')[1].split(')')[0].split(',');
  const [cos, sin] = values.map(Number);

  return [cos, sin];
}
