export const drawCircle = (
  ctx: CanvasRenderingContext2D,
  x: number = 0,
  y: number = 0,
  r: number,
  color: string
) => {
  ctx.beginPath();
  ctx.arc(x, y, r, 0, 2 * Math.PI, false);
  ctx.fillStyle = color;
  ctx.fill();
};
